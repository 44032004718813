body {
    margin: 0;
}

body,
input,
div,
p,
textarea,
span {
    font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
        "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

html,
body {
    width: 100%;
    height: 100%;
}

#root {
    height: 100%;
}

#toast-root {
    width: 100%;
    position: fixed;
    bottom: 0px;

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    /* just for styling to see the limits */
    /* border: 2px dashed red; */
    /* box-sizing: border-box; */

    /* width should be same as mobileWidth */
    @media (max-width: 620px) {
        z-index: 200;
    }
}

#guide-root {
    position: fixed;
    width: 100vw;
    /* height: 100vh; */
    /* background: red; */

    top: 0px;
    right: 0px;
    left: 0px;
    /* bottom: 0px; */
    z-index: 50;
}
